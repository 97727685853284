import {
    FETCH_YEAR_LEVEL_STATS,
    FETCH_YEAR_LEVEL_STATS_SUCCESS,
    FETCH_YEAR_LEVEL_STATS_FAIL,
    FETCH_SHOW_LEVEL_STATS,
    FETCH_SHOW_LEVEL_STATS_FAIL,
    FETCH_SHOW_LEVEL_STATS_SUCCESS,
    FETCH_SHOW_LEVEL_SHOWS_STATS,
    FETCH_SHOW_LEVEL_SHOWS_STATS_SUCCESS,
    FETCH_SHOW_LEVEL_SHOWS_STATS_FAIL
} from './actionTypes';

export const fetchYearLevelStats = (data, token) => ({
    type: FETCH_YEAR_LEVEL_STATS,
    payload: data,
    token: token,
});

export const fetchYearLevelStatsSuccess = (stats) => ({
    type: FETCH_YEAR_LEVEL_STATS_SUCCESS,
    payload: stats
});

export const fetchYearLevelStatsFail = (error) => ({
    type: FETCH_YEAR_LEVEL_STATS_FAIL,
    payload: error
});

export const fetchShowLevelStats = (data, token) => ({
    type: FETCH_SHOW_LEVEL_STATS,
    payload: data,
    token: token,
});

export const fetchShowLevelStatsSuccess = (stats) => ({
    type: FETCH_SHOW_LEVEL_STATS_SUCCESS,
    payload: stats
});

export const fetchShowLevelStatsFail = (error) => ({
    type: FETCH_SHOW_LEVEL_STATS_FAIL,
    payload: error
});

export const fetchShowLevelShowsStats = (data, token) => ({
    type: FETCH_SHOW_LEVEL_SHOWS_STATS,
    payload: data,
    token: token,
});

export const fetchShowLevelShowsStatsSuccess = (stats) => ({
    type: FETCH_SHOW_LEVEL_SHOWS_STATS_SUCCESS,
    payload: stats
});

export const fetchShowLevelShowsStatsFail = (error) => ({
    type: FETCH_SHOW_LEVEL_SHOWS_STATS_FAIL,
    payload: error
});