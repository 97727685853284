import { Button, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchYearLevelStats } from '../../store/stats/actions';
import { useAuth } from '../../hooks/useAuth';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { debounce } from 'lodash';
import { Box, CircularProgress } from '@mui/material';
import ContentLoader from 'react-content-loader';

const provinces = [
    { value: '1', label: 'Alberta' },
    { value: '2', label: 'BC' },
    { value: '3', label: 'Manitoba' },
    { value: '4', label: 'New Brunswick' },
    { value: '5', label: 'Newfoundland' },
    { value: '6', label: 'North West Territories' },
    { value: '7', label: 'Nova Scotia' },
    { value: '8', label: 'Nunavut' },
    { value: '9', label: 'Ontario' },
    { value: '10', label: 'Prince Edward Island' },
    { value: '11', label: 'Quebec' },
    { value: '12', label: 'Saskatchewan' },
    { value: '13', label: 'Yukon' }
];

const graphTypes = [
    { value: 'TotalDogs', label: 'Total Number of Dogs Competing' },
    { value: 'NumOfEvents', label: 'Total Number of Shows' }
];

const currentYear = new Date().getFullYear();
const years = [...Array(currentYear - 2000 + 1)].map((_, index) => (currentYear - index).toString());

function YearLevel() {
    const dispatch = useDispatch();
    const { user, logout } = useAuth();
    const [startYear, setStartYear] = useState();
    const [endYear, setEndYear] = useState();
    const [province, setProvince] = useState();
    const [provinceLabel, setProvinceLabel] = useState('');
    const chartRef = useRef(null);
    const [noResults, setNoResults] = useState(false);
    const [graphType, setGraphType] = useState('TotalDogs');

    // Handle dropdown changes and API call
    const handleDropdownChange = () => {
        setProvinceLabel(province ? provinces.find(p => p.value === province)?.label : '');

        if (startYear && endYear && parseInt(startYear) > parseInt(endYear)) {
            toast.error('Start Year must be less than or equal to End Year.');
            return;
        }

        const payload = {
            startYear: startYear || 2000,
            endYear: endYear || 2050,
        };

        if (province) payload.province = province;

        // Dispatch the API call if valid data is present
        if (Object.keys(payload).length > 0) {
            dispatch(fetchYearLevelStats(payload, user.token));
        } else {
            // Optional: If all dropdowns are empty, you can fetch data without filters
            dispatch(fetchYearLevelStats({}, user.token));
        }

    };

    // call API whenever any dropdown value changes
    useEffect(() => {
        handleDropdownChange();
    }, [startYear, endYear, province]);

    //get the responce of api from redux store
    const yearLevelStats = useSelector((state) => {
        return state?.statsReducer?.yearLevelStateResponse || []
    });

    const loading = useSelector((state) => {
        return state?.statsReducer?.loading || null;
    });

    const formattedYearLevelStats = yearLevelStats.map(item => ({
        ...item,
        TotalDogs: Number(item.TotalDogs),
        NumOfEvents: Number(item.NumOfEvents),
        // NumberOfShows: Number(item.NumberOfShows),
        // SingleBreed: Number(item.SingleBreed),
        // MultiBreed: Number(item.MultiBreed),
        // GroupBreed: Number(item.GroupBreed),
        // LimitedBreed: Number(item.LimitedBreed),
    }));

    // Check for no results
    useEffect(() => {
        setNoResults(formattedYearLevelStats.length === 0 && !loading);
    }, [formattedYearLevelStats, loading]);

    // useEffect(() => {
    //     if (chartRef.current && yearLevelStats.length > 0) {

    //         const root = am5.Root.new(chartRef.current);

    //         // Apply themes
    //         root.setThemes([am5themes_Animated.new(root)]);

    //         // Create chart
    //         const chart = root.container.children.push(
    //             am5xy.XYChart.new(root, {
    //                 paddingLeft: 0,
    //                 layout: root.verticalLayout,
    //             })
    //         );

    //         // Add scrollbar
    //         chart.set(
    //             'scrollbarY',
    //             am5.Scrollbar.new(root, { orientation: 'vertical' })
    //         );

    //         // Create axes
    //         const yAxis = chart.yAxes.push(
    //             am5xy.CategoryAxis.new(root, {
    //                 categoryField: 'TheYear',
    //                 renderer: am5xy.AxisRendererY.new(root, {}),
    //             })
    //         );

    //         yAxis.data.setAll(formattedYearLevelStats);

    //         const xAxis = chart.xAxes.push(
    //             am5xy.ValueAxis.new(root, {
    //                 min: 0,
    //                 renderer: am5xy.AxisRendererX.new(root, {
    //                     minGridDistance: 1000,
    //                     labels: {
    //                         visible: false, // Hide labels on X axis
    //                     }
    //                 }),
    //             })
    //         );

    //         // Add legend
    //         const legend = chart.children.push(
    //             am5.Legend.new(root, { centerX: am5.p50, x: am5.p50 })
    //         );

    //         // Create series dynamically
    //         const fields = [
    //             'NumberOfShows',
    //             'SingleBreed',
    //             'MultiBreed',
    //             'GroupBreed',
    //             'LimitedBreed',
    //         ];

    //         const fieldLabels = {
    //             NumberOfShows: 'Number Of Shows',
    //             SingleBreed: 'Single Breed',
    //             MultiBreed: 'Multi Breed',
    //             GroupBreed: 'Group Breed',
    //             LimitedBreed: 'Limited Breed',
    //         };

    //         const colorPalette = [
    //             am5.color(0xDAA520), // Golden Rod
    //             am5.color(0x6A5ACD), // Slate Blue
    //             am5.color(0x33FF57), // Green
    //             am5.color(0xFF5733), // Red
    //             am5.color(0x3357FF), // Blue
    //             am5.color(0xFF33FF), // Pink
    //             am5.color(0xFF4500), // Orange-Red
    //             am5.color(0x40E0D0), // Turquoise
    //             am5.color(0xDA70D6), // Orchid
    //             am5.color(0xFFFF33), // Yellow
    //             am5.color(0xFF33A1), // Magenta
    //             am5.color(0xDC143C), // Crimson
    //             am5.color(0x2E8B57), // Sea Green
    //             am5.color(0x8A2BE2), // Blue Violet
    //             am5.color(0xFF6347), // Tomato
    //             am5.color(0x4682B4), // Steel Blue
    //             am5.color(0xD2691E), // Chocolate
    //             am5.color(0xFF1493), // Deep Pink
    //             am5.color(0x00FA9A), // Medium Spring Green
    //             am5.color(0xFF8C00), // Dark Orange
    //             am5.color(0x7FFF00), // Chartreuse
    //         ];

    //         fields.forEach((field,index) => {
    //             const series = chart.series.push(
    //                 am5xy.ColumnSeries.new(root, {
    //                     name: fieldLabels[field],
    //                     stacked: true,
    //                     xAxis: xAxis,
    //                     yAxis: yAxis,
    //                     valueXField: field,
    //                     categoryYField: 'TheYear',
    //                     maxWidth: am5.percent(10),
    //                     minWidth: am5.percent(2),
    //                 })
    //             );

    //             series.columns.template.setAll({
    //                 fill: colorPalette[index % colorPalette.length], // Use color from the palette
    //                 stroke: am5.color(0xFFFFFF),                    // White border
    //                 strokeWidth: 1,                   
    //                 tooltipText: '[bold]Year:[/] {categoryY},\n[bold]{name} Count:[/] {valueX}',
    //                 tooltipY: am5.percent(90),
    //             });
    //             series.data.setAll(formattedYearLevelStats);

    //             series.appear();

    //             legend.data.push(series);
    //         });

    //         chart.appear(1000, 100);

    //         return () => {
    //             root.dispose();
    //         };
    //     }
    // }, [formattedYearLevelStats]);

    useEffect(() => {
        if (chartRef.current && yearLevelStats.length > 0) {

            const root = am5.Root.new(chartRef.current);

            // Set themes
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create chart
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 0,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout
            }));

            // Add legend
            var legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                })
            );

            // Format data for chart
            const formattedData = formattedYearLevelStats.map(item => ({
                year: item.TheYear,  // X-axis: Year
                TotalDogs: item.TotalDogs,  // Y-axis: Total number of dogs competing
                NumOfEvents: item.NumOfEvents,  // Y-axis: Total number of shows (events)
            }));

            // Create axes
            var xRenderer = am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                // minorGridEnabled: true
                minGridDistance: 50,  // Adjust the minimum distance between grid lines (to reduce label overcrowding)
                labels: {
                    rotation: -45,    // Rotate the labels slightly to avoid overlap
                    centerY: am5.p50,
                    centerX: am5.p100
                }
            });

            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {})
            }));

            xRenderer.grid.template.setAll({
                location: 1
            });

            xAxis.data.setAll(formattedData);

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {
                    strokeOpacity: 0.1
                })
            }));

            // Function to create series
            function makeSeries(name, fieldName) {
                var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    categoryXField: "year"
                }));

                series.columns.template.setAll({
                    tooltipText: "[bold]{name}:[/] {valueY}",
                    width: am5.percent(90),
                    tooltipY: 0,
                    strokeOpacity: 0
                });

                series.data.setAll(formattedData);

                // Add value label on the bars
                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationY: 0,
                        sprite: am5.Label.new(root, {
                            text: "{valueY}",
                            fill: root.interfaceColors.get("alternativeText"),
                            centerY: 0,
                            centerX: am5.p50,
                            populateText: true
                        })
                    });
                });

                legend.data.push(series);
            }

            graphType === 'TotalDogs' ? makeSeries("Total Number of Dogs Competing", "TotalDogs") : makeSeries("Total Number of Shows", "NumOfEvents");

            // Make stuff animate on load
            chart.appear(1000, 100);

            return () => {
                root.dispose();
            };
        }
    }, [formattedYearLevelStats]);

    return (
        <div style={{ backgroundColor: '#F1F2F4' }}>
            <p className='page-heading'>
                Year Level Stats
            </p>

            <ToastContainer />

            <div className='page-body'>
                <Card className="my-2" style={{ padding: 0 }}>
                    <CardHeader>
                        <Form>
                            <FormGroup row>
                                <Col sm={2}>
                                    <Input
                                        id="startYear"
                                        name="startYear"
                                        type="select"
                                        value={startYear}
                                        onChange={(e) => setStartYear(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Start Year</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={2}>
                                    <Input
                                        id="endYear"
                                        name="endYear"
                                        type="select"
                                        value={endYear}
                                        onChange={(e) => setEndYear(e.target.value)}
                                        required
                                    >
                                        <option value="">Select End Year</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={4}>
                                    <Input
                                        id="province"
                                        name="province"
                                        type="select"
                                        value={province}
                                        onChange={(e) => setProvince(e.target.value)}
                                        required
                                    >
                                        <option value="">All Province</option>
                                        {provinces.map((prov) => (
                                            <option key={prov.value} value={prov.value}>
                                                {prov.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={4}>
                                    <Input
                                        id="graphType"
                                        name="graphType"
                                        type="select"
                                        value={graphType}
                                        onChange={(e) => setGraphType(e.target.value)}
                                        required
                                    >
                                        {graphTypes.map((type) => (
                                            <option key={type.value} value={type.value}>
                                                {type.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardHeader>
                    <CardBody>
                        {loading && !noResults &&
                            <div className="loader" >
                                <ContentLoader
                                    speed={1}
                                    width={'100%'}
                                    height={500} // Adjust the height based on the number of lines and gap
                                    viewBox={`0 0 100% ${700}`}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    {Array.from({ length: 10 }, (_, index) => (
                                        <rect
                                            key={index}
                                            x="0"
                                            y={(70 + 4) * index}
                                            rx="5"
                                            ry="5"
                                            width="100%"
                                            height="700"
                                        />
                                    ))}
                                </ContentLoader>
                            </div>
                        }
                        {!loading && noResults &&
                            <div>
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <strong style={{ fontSize: '20px' }} >
                                        {provinceLabel ? 'Province - ' + provinceLabel : "All Province"}
                                        {` - ${graphType === 'TotalDogs' ? 'Total Number of Dogs Competing' : 'Total Number of Shows'}`}
                                    </strong>
                                </div>
                                <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>No result found</p>
                                </div>
                            </div>
                        }
                        {!loading && !noResults &&
                            <div>
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <strong style={{ fontSize: '20px' }}>
                                        {provinceLabel ? 'Province - ' + provinceLabel : "All Province"}
                                        {` - ${graphType === 'TotalDogs' ? 'Total Number of Dogs Competing' : 'Total Number of Shows'}`}
                                    </strong>
                                </div>
                                <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '700px' }}></div>
                            </div>
                        }
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default YearLevel;
