import { Button } from 'reactstrap';
import React from 'react';
import { Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editBreedGroup } from '../../store/dogs/actions';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

function EditBreedData() {
  const { loading } = useSelector((state) => state.DogsReducer);
  const { user } = useAuth();

  const location = useLocation();

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const breedGroupData = location.state.data;
  
  const editBreed = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const breedData = {
      breedGroupid: breedGroupData._id,
      group: data.get("group"),
      breed: data.get("breed"),
      certificateName: data.get("certificateName"),
      breedYear: data.get("breedYear"),
    }
    dispatch(editBreedGroup(breedData, user.token, navigate));
  };

  const cancelEdit = () => {
    navigate('/dashboard/allBreedsTable');
  }

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>Edit Breed Group</p>
      <div className='page-body' >
        <CardBody>
          <Form onSubmit={editBreed}>
            <FormGroup row>
              <Label for="group" sm={2}> Select Group </Label>
              <Col sm={10}>
                <Input id="group" name="group" type="select" defaultValue={breedGroupData.GroupName} required>
                  <option value="" disabled>Select Group</option>
                  <option value="Group 1">Group 1</option>
                  <option value="Group 2">Group 2</option>
                  <option value="Group 3">Group 3</option>
                  <option value="Group 4">Group 4</option>
                  <option value="Group 5">Group 5</option>
                  <option value="Group 6">Group 6</option>
                  <option value="Group 7">Group 7</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="breed" sm={2}> Breed Name </Label>
              <Col sm={10}>
                <Input id="breed" name="breed" placeholder="Enter Breed Name" type="text" defaultValue={breedGroupData.CKCBreedName} required/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="certificateName" sm={2}> Certificate Breed Name </Label>
              <Col sm={10}>
                <Input id="certificateName" name="certificateName" placeholder="Enter Certificate Breed Name" type="text" defaultValue={breedGroupData.CertificateName} required/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="breedYear" sm={2}> Select Year </Label>
              <Col sm={10}>
                <Input id="breedYear" name="breedYear" type="select" defaultValue={breedGroupData.BreedYear} required>
                  {[...Array(3)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                  })}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ offset: 2, size: 10 }}>
                <Button color='primary' outline type='submit'>
                  { loading ? ( 'Saving...') : ('Save Changes') }
                </Button>{' '}
                <Button color="secondary" onClick={cancelEdit}>
                  Cancel
                </Button>
                <ToastContainer />
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </div>
    </div>
  );
}

export default EditBreedData;
