import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";

import {tryLogin} from "../store/adminLogin/actions";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate } from "react-router-dom";


export const LoginPage = () => {
  const { login } = useAuth();
  const { loginResponse, loading } = useSelector((state) => state.LoginReducer);
  const navigate = useNavigate();

    let dispatch = useDispatch();

      const handleSubmit = async (event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
          const loginData={
            "email":data.get("email"),
            "password":data.get("password")
                  };
        // console.log("login data",loginData);

         dispatch(tryLogin(loginData,login));
      
  };
 

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >

        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
{/* <Button onClick={ ()=>navigate("/forgot-password")}>Forgot Password?</Button> */}


          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >{loading  ? (
            'Loading...'
                 ) : (
           'Log In'
                   )}
          </Button>
          
          <ToastContainer />

        </Box>

      </Box>
    </Container>
  );
};
