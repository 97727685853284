import { takeLatest, put, call } from 'redux-saga/effects';
import {
  FETCH_YEAR_LEVEL_STATS,
  FETCH_YEAR_LEVEL_STATS_SUCCESS,
  FETCH_YEAR_LEVEL_STATS_FAIL,
  FETCH_SHOW_LEVEL_STATS,
  FETCH_SHOW_LEVEL_SHOWS_STATS
} from './actionTypes';
import { fetchYearLevelStatsSuccess, fetchYearLevelStatsFail, fetchShowLevelStatsFail, fetchShowLevelStatsSuccess, fetchShowLevelShowsStatsSuccess, fetchShowLevelShowsStatsFail } from './actions';
import { showLevelStats, yearLevelStats } from '../../helpers/backend_helper';

function* onFetchYearLevelStats({ payload: data, token }) {
  try {
    const response = yield call(yearLevelStats, data, token);
    if (response.statusCode === 200 && response.data) {
      yield put(fetchYearLevelStatsSuccess(response.data));
    } else {
      yield put(fetchYearLevelStatsFail('Unexpected API response'));
    }
  } catch (error) {
    yield put(fetchYearLevelStatsFail(error.response.data));
  }
}

function* onFetchShowLevelStats({ payload: data, token }) {
  try {
    const response = yield call(showLevelStats, data, token);
    if (response.statusCode === 200 && response.data) {
      yield put(fetchShowLevelStatsSuccess(response.data));
    } else {
      yield put(fetchShowLevelStatsFail('Unexpected API response'));
    }
    // yield put(fetchShowLevelStatsSuccess(response?.data || {}));
  } catch (error) {
    yield put(fetchShowLevelStatsFail(error.response.data));
  }
}

function* onFetchShowLevelShowsStats({ payload: data, token }) {
  try {
    const response = yield call(showLevelStats, data, token);
    if (response.statusCode === 200 && response.data) {
      yield put(fetchShowLevelShowsStatsSuccess(response.data));
    } else {
      yield put(fetchShowLevelShowsStatsFail('Unexpected API response'));
    }
    // yield put(fetchShowLevelShowsStatsSuccess(response?.data || {}));
  } catch (error) {
    yield put(fetchShowLevelShowsStatsFail(error.response.data));
  }
}

function* statsSaga() {
  yield takeLatest(FETCH_YEAR_LEVEL_STATS, onFetchYearLevelStats);
  yield takeLatest(FETCH_SHOW_LEVEL_STATS, onFetchShowLevelStats);
  yield takeLatest(FETCH_SHOW_LEVEL_SHOWS_STATS, onFetchShowLevelShowsStats);
}

export default statsSaga;