import { Button, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowLevelStats } from '../../store/stats/actions';
import { useAuth } from '../../hooks/useAuth';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { debounce } from 'lodash';
import { Box, CircularProgress } from '@mui/material';
import ContentLoader from 'react-content-loader';

const provinces = [
    { value: '1', label: 'Alberta' },
    { value: '2', label: 'BC' },
    { value: '3', label: 'Manitoba' },
    { value: '4', label: 'New Brunswick' },
    { value: '5', label: 'Newfoundland' },
    { value: '6', label: 'North West Territories' },
    { value: '7', label: 'Nova Scotia' },
    { value: '8', label: 'Nunavut' },
    { value: '9', label: 'Ontario' },
    { value: '10', label: 'Prince Edward Island' },
    { value: '11', label: 'Quebec' },
    { value: '12', label: 'Saskatchewan' },
    { value: '13', label: 'Yukon' }
];

const breedTypes = [
    { value: 'SingleBreed', label: 'Single Breed' },
    { value: 'MultiBreed', label: 'Multi Breed' },
    { value: 'GroupBreed', label: 'Group Breed' },
    { value: 'LimitedBreed', label: 'Limited Breed' },
];

const GroupTypes = [
    { value: 'TotalGroup1', label: 'Group 1' },
    { value: 'TotalGroup2', label: 'Group 2' },
    { value: 'TotalGroup3', label: 'Group 3' },
    { value: 'TotalGroup4', label: 'Group 4' },
    { value: 'TotalGroup5', label: 'Group 5' },
    { value: 'TotalGroup6', label: 'Group 6' },
    { value: 'TotalGroup7', label: 'Group 7' },
];

const currentYear = new Date().getFullYear();
const years = [...Array(currentYear - 2000 + 1)].map((_, index) => (currentYear - index).toString());

function BreedLevel() {
    const dispatch = useDispatch();
    const { user, logout } = useAuth();
    const [startYear, setStartYear] = useState();
    const [endYear, setEndYear] = useState();
    const [province, setProvince] = useState();
    const [provinceLabel, setProvinceLabel] = useState('');
    const [selectedShowName, setSelectedShowName] = useState('');
    const [selectedBreedType, setSelectedBreedType] = useState(breedTypes.length > 0 ? breedTypes[0].value : "");
    const [selectedGroupType, setSelectedGroupType] = useState("");
    const chartRef = useRef(null);
    const [noResults, setNoResults] = useState(false);

    // Handle dropdown changes and API call
    const handleDropdownChange = () => {
        // setProvinceLabel(province ? provinces.find(p => p.value === province)?.label : '');

        if (startYear && endYear && parseInt(startYear) > parseInt(endYear)) {
            toast.error('Start Year must be less than or equal to End Year.');
            return;
        }

        const payload = {
            startYear: startYear || 2000,
            endYear: endYear || 2050,
        };

        // if (province) payload.province = province;

        // Dispatch the API call if valid data is present
        if (Object.keys(payload).length > 0) {
            dispatch(fetchShowLevelStats(payload, user.token));
        } else {
            // Optional: If all dropdowns are empty, you can fetch data without filters
            dispatch(fetchShowLevelStats({}, user.token));
        }

    };

    // call API whenever any dropdown value changes
    useEffect(() => {
        handleDropdownChange();
    }, [startYear, endYear, selectedShowName, selectedBreedType]);


    //get the responce of api from redux store
    const showLevelShowsData = useSelector((state) => {
        return state?.statsReducer?.showLevelStateResponse || [];
    });

    const loading = useSelector((state) => {
        return state?.statsReducer?.loading || null;
    });

    let showNames = [...new Set(showLevelShowsData.map(show => show.ShowName.toLowerCase()))];
    const capitalizeWords = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };
    showNames = showNames.map(showName => capitalizeWords(showName));

    useEffect(() => {
        if (showNames.length > 0 && !selectedShowName) {
            setSelectedShowName(showNames[0]);
        }
    }, [showNames, selectedShowName]);

    const groupedByYear = showLevelShowsData.reduce((acc, current) => {
        const year = current.TheYear;
        const showName = current.ShowName.toLowerCase();
        const singleBreed = parseInt(current.SingleBreed, 10) || 0;
        const multiBreed = parseInt(current.MultiBreed, 10) || 0;
        const groupBreed = parseInt(current.GroupBreed, 10) || 0;
        const limitedBreed = parseInt(current.LimitedBreed, 10) || 0;
        const totalGroup1 = parseInt(current.TotalGroup1, 10) || 0;
        const totalGroup2 = parseInt(current.TotalGroup2, 10) || 0;
        const totalGroup3 = parseInt(current.TotalGroup3, 10) || 0;
        const totalGroup4 = parseInt(current.TotalGroup4, 10) || 0;
        const totalGroup5 = parseInt(current.TotalGroup5, 10) || 0;
        const totalGroup6 = parseInt(current.TotalGroup6, 10) || 0;
        const totalGroup7 = parseInt(current.TotalGroup7, 10) || 0;

        if (!acc[year]) {
            acc[year] = {};
        }

        if (!acc[year][showName]) {
            acc[year][showName] = {
                ShowName: showName,
                SingleBreed: 0,
                MultiBreed: 0,
                GroupBreed: 0,
                LimitedBreed: 0,
                TotalGroup1: 0,
                TotalGroup2: 0,
                TotalGroup3: 0,
                TotalGroup4: 0,
                TotalGroup5: 0,
                TotalGroup6: 0,
                TotalGroup7: 0,
            };
        }

        acc[year][showName].SingleBreed += singleBreed;
        acc[year][showName].MultiBreed += multiBreed;
        acc[year][showName].GroupBreed += groupBreed;
        acc[year][showName].LimitedBreed += limitedBreed;
        acc[year][showName].TotalGroup1 += totalGroup1;
        acc[year][showName].TotalGroup2 += totalGroup2;
        acc[year][showName].TotalGroup3 += totalGroup3;
        acc[year][showName].TotalGroup4 += totalGroup4;
        acc[year][showName].TotalGroup5 += totalGroup5;
        acc[year][showName].TotalGroup6 += totalGroup6;
        acc[year][showName].TotalGroup7 += totalGroup7;

        return acc;
    }, {});

    console.log("groupedByYear >> ", groupedByYear);

    // const filterDataByShowNameAndBreedType = (groupedByYear, selectedShowName, selectedBreedType) => {
    //     const selectedBreedTypeKey = selectedBreedType.charAt(0).toUpperCase() + selectedBreedType.slice(1); // Capitalize first letter
    //     const result = [];

    //     // Loop through each year in the groupedByYear object
    //     for (const year in groupedByYear) {
    //         const showsInYear = groupedByYear[year];

    //         // Loop through each show in that year
    //         for (const showName in showsInYear) {
    //             // If the show name matches the selected show name
    //             if (showName.toLowerCase() === selectedShowName.toLowerCase()) {
    //                 const showData = showsInYear[showName];

    //                 // Calculate the total count for the selected breed type
    //                 const count = showData[selectedBreedTypeKey] || 0;

    //                 // Push the result for this year
    //                 result.push({
    //                     year: parseInt(year), // Ensure year is a number
    //                     count: count,
    //                     showname: showData.ShowName,
    //                 });
    //             }
    //         }
    //     }

    //     return result;
    // };
    const filterDataByShowNameAndBreedType = (groupedByYear, selectedShowName, selectedBreedType, selectedGroupType) => {
        // let selectedBreedTypeKey = selectedBreedType.charAt(0).toUpperCase() + selectedBreedType.slice(1);
        let selectedTypeKey = "";

        if (selectedBreedType) {
            // Convert selected breed type to match the data key format (capitalize first letter)
            selectedTypeKey = selectedBreedType.charAt(0).toUpperCase() + selectedBreedType.slice(1);
        } else if (selectedGroupType) {
            // If no breed type is selected but group type is, use the group type key
            selectedTypeKey = selectedGroupType.charAt(0).toUpperCase() + selectedGroupType.slice(1);
        }
        console.log("selectedTypeKey >> ", selectedTypeKey);
        // If neither type is selected, return an empty array
        if (!selectedTypeKey) {
            return [];
        }

        const result = [];

        // Loop through each year in the groupedByYear object
        for (const year in groupedByYear) {
            const showsInYear = groupedByYear[year];
            let showFound = false; // Flag to track if the show was found for this year

            // Loop through each show in that year
            for (const showName in showsInYear) {
                // If the show name matches the selected show name
                if (showName.toLowerCase() === selectedShowName.toLowerCase()) {
                    const showData = showsInYear[showName];

                    // Calculate the total count for the selected breed type
                    const count = showData[selectedTypeKey] || 0;

                    // Push the result for this year with the found show
                    result.push({
                        year: parseInt(year), // Ensure year is a number
                        count: count,
                        showname: showData.ShowName,
                    });

                    showFound = true; // Set flag to true, as the show was found
                    break; // Break as we've already found the show for this year
                }
            }

            if (!showFound) {
                result.push({
                    year: parseInt(year),
                    count: 0,
                    showname: selectedShowName,
                });
            }
        }

        return result;
    };

    const formattedBreedLevelStats = filterDataByShowNameAndBreedType(groupedByYear, selectedShowName, selectedBreedType, selectedGroupType);
    const selectedBreedLabel = breedTypes.find(b => b.value === selectedBreedType)?.label || selectedBreedType;
    const selectedGroupLabel = GroupTypes.find(b => b.value === selectedGroupType)?.label || selectedGroupType;

    console.log("formattedBreedLevelStats >> pp >> ", formattedBreedLevelStats);

    // Check for no results
    const allCountsZero = formattedBreedLevelStats.every(item => item.count === 0);
    useEffect(() => {
        setNoResults((formattedBreedLevelStats.length === 0 || allCountsZero) && !loading);
    }, [formattedBreedLevelStats, loading]);

    // console.log("formattedBreedLevelStats >> ", formattedBreedLevelStats);

    useEffect(() => {
        if (chartRef.current && formattedBreedLevelStats.length > 0 && !allCountsZero) {

            const root = am5.Root.new(chartRef.current);

            // Set themes
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create chart
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 0,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout
            }));

            // Add legend
            var legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                })
            );

            // Format data for chart
            const formattedData = formattedBreedLevelStats.map(item => ({
                year: `${item.year}`,
                count: item.count
            }));

            // Create axes
            var xRenderer = am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                // minorGridEnabled: true
                minGridDistance: 50,  // Adjust the minimum distance between grid lines (to reduce label overcrowding)
                labels: {
                    rotation: -45,    // Rotate the labels slightly to avoid overlap
                    centerY: am5.p50,
                    centerX: am5.p100
                }
            });

            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {})
            }));

            // xAxis.get("renderer").labels.template.setAll({
            //     oversizedBehavior: "none",
            //     minPosition: 0.05,            // Ensure labels start from a little margin
            //     maxPosition: 0.95             // Ensure labels end with some margin
            // });

            xRenderer.grid.template.setAll({
                location: 1
            });

            xAxis.data.setAll(formattedData);

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {
                    strokeOpacity: 0.1
                })
            }));

            // Function to create series
            function makeSeries(name, fieldName) {
                var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    categoryXField: "year"
                }));

                series.columns.template.setAll({
                    tooltipText: `[bold]${selectedBreedLabel ? selectedBreedLabel : selectedGroupLabel}:[/] {valueY}`,
                    width: am5.percent(90),
                    tooltipY: 0,
                    strokeOpacity: 0
                });

                series.data.setAll(formattedData);

                // Add value label on the bars
                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationY: 0,
                        sprite: am5.Label.new(root, {
                            text: "{valueY}",
                            fill: root.interfaceColors.get("alternativeText"),
                            centerY: 0,
                            centerX: am5.p50,
                            populateText: true
                        })
                    });
                });

                legend.data.push(series);
            }

            makeSeries(`${selectedBreedLabel ? selectedBreedLabel : selectedGroupLabel}`, "count");

            // Make stuff animate on load
            chart.appear(1000, 100);

            return () => {
                root.dispose();
            };
        }
    }, [formattedBreedLevelStats]);

    console.log("selectedBreedType >> ", selectedBreedType);

    return (
        <div style={{ backgroundColor: '#F1F2F4' }}>
            <p className='page-heading'>
                Breed Level Stats
            </p>

            <ToastContainer />

            <div className='page-body'>
                <Card className="my-2" style={{ padding: 0 }}>
                    <CardHeader>
                        <Form>
                            <FormGroup row>
                                <Col sm={2}>
                                    <Input
                                        id="startYear"
                                        name="startYear"
                                        type="select"
                                        value={startYear}
                                        onChange={(e) => setStartYear(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Start Year</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={2}>
                                    <Input
                                        id="endYear"
                                        name="endYear"
                                        type="select"
                                        value={endYear}
                                        onChange={(e) => setEndYear(e.target.value)}
                                        required
                                    >
                                        <option value="">Select End Year</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                {/* <Col sm={4}>
                                    <Input
                                        id="province"
                                        name="province"
                                        type="select"
                                        value={province}
                                        onChange={(e) => setProvince(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Province</option>
                                        {provinces.map((prov) => (
                                            <option key={prov.value} value={prov.value}>
                                                {prov.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col> */}
                                <Col sm={4}>
                                    <Input
                                        id="showName"
                                        name="showName"
                                        type="select"
                                        value={selectedShowName}
                                        onChange={(e) => setSelectedShowName(e.target.value)}
                                    >
                                        {/* <option value="">Select Show Name</option> */}
                                        {showNames.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={2}>
                                    <Input
                                        id="breedType"
                                        name="breedType"
                                        type="select"
                                        value={selectedBreedType}
                                        onChange={(e) => { setSelectedBreedType(e.target.value); setSelectedGroupType("") }}
                                    >
                                        <option value="">Select Breed Type</option>
                                        {breedTypes.map((breed) => (
                                            <option key={breed.value} value={breed.value}>
                                                {breed.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                <Col sm={2}>
                                    <Input
                                        id="groupType"
                                        name="groupType"
                                        type="select"
                                        value={selectedGroupType}
                                        onChange={(e) => { setSelectedGroupType(e.target.value); setSelectedBreedType(""); }}
                                    >
                                        <option value="">Select Group Type</option>
                                        {GroupTypes.map((group) => (
                                            <option key={group.value} value={group.value}>
                                                {group.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardHeader>
                    <CardBody>
                        {loading && !noResults &&
                            <div className="loader" >
                                <ContentLoader
                                    speed={1}
                                    width={'100%'}
                                    height={700} // Adjust the height based on the number of lines and gap
                                    viewBox={`0 0 100% ${700}`}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    {Array.from({ length: 10 }, (_, index) => (
                                        <rect
                                            key={index}
                                            x="0"
                                            y={(70 + 4) * index}
                                            rx="5"
                                            ry="5"
                                            width="100%"
                                            height="700"
                                        />
                                    ))}
                                </ContentLoader>
                            </div>
                        }
                        {!loading && noResults &&
                            <div>
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <strong style={{ fontSize: '20px' }} >{selectedBreedLabel ? selectedShowName + ' - ' + selectedBreedLabel + " Count" : selectedShowName + ' - ' + selectedGroupLabel + " Count"}</strong>
                                </div>
                                <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>No result found</p>
                                </div>
                            </div>
                        }
                        {!loading && !noResults &&
                            <div>
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <strong style={{ fontSize: '20px' }}>{selectedBreedLabel ? selectedShowName + ' - ' + selectedBreedLabel + " Count" : selectedShowName + ' - ' + selectedGroupLabel + " Count"}</strong>
                                </div>
                                <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '700px' }}></div>
                            </div>
                        }
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default BreedLevel;
