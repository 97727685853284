import { takeLatest, put, call } from "redux-saga/effects";

import { GET_POSTING_DATES, SAVE_POSTING_DATES, EDIT_DISPLAY_YEAR} from "./actionTypes";

import {
  getPostingDatesFail,
  getPostingDatesSuccess,
  savePostingDatesFail,
  savePostingDatesSuccess,
  editDisplayYearSuccess,
  editDisplayYearFail
  
} from "./actions";

import { getPostingDates,savePostingDates,editPostingDates} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const delay = ms => new Promise(res => setTimeout(res, ms));

function* onGetPostingDates({token}) {
  try {
    const response = yield call(getPostingDates,token);
    yield put(getPostingDatesSuccess(response.data));
  } catch (error) {
    yield put(getPostingDatesFail(error.response.data));
  }
}

function* onSavePostingDates({ payload: data,token }) {

  
  try {
    const response = yield call(savePostingDates,data,token);
    yield put(savePostingDatesSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(savePostingDatesFail(error.response));
  }
}

function* onEditPostingDates({ payload: data, navigate, token }) {
  try {
    const response = yield call(editPostingDates,data,token);
    toast(response.data);
    //yield call(delay, 500);
    if(response.statusCode === 200){
      //navigate(0);
    }
    yield put(editDisplayYearSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(editDisplayYearFail(error.response));
  }
}


function* PostingDatesSaga() {

  yield takeLatest(SAVE_POSTING_DATES, onSavePostingDates);
  yield takeLatest(GET_POSTING_DATES, onGetPostingDates);
  yield takeLatest(EDIT_DISPLAY_YEAR, onEditPostingDates);

}

export default PostingDatesSaga;
