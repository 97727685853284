import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer } from "react-toastify";

function Judges() {

    return (
        <div style={{ backgroundColor: '#F1F2F4' }}>
            <p className='page-heading'>
                Judges Stats
            </p>

            <ToastContainer />

            <div className='page-body'>

            </div>
        </div>
    );
}

export default Judges;
